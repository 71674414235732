export const data = [
  {
    title: 'Gliwice',
    map:
      'https://www.google.com/maps/search/?api=1&query=Zwycięstwa+52,+44-100+Gliwice',
    city: 'Gliwice',
    street: 'Zwycięstwa 52',
    postal: '44-100',
    country: 'Poland',
  },
  {
    title: 'Warsaw',
    map:
      'https://www.google.com/maps/search/?api=1&query=Krucza+50,+00-025+Warszawa',
    city: 'Warsaw',
    street: 'Krucza 50',
    postal: '00-025',
    country: 'Poland',
  },
  {
    title: 'Wroclaw',
    map:
      'https://www.google.com/maps/search/?api=1&query=Wyspa+Slodowa+7,+50-266+Wroclaw',
    city: 'Wroclaw',
    street: 'Wyspa Słodowa 7',
    postal: '50-266',
    country: 'Poland',
  },
  {
    title: 'Cracow',
    map:
      'https://www.google.com/maps/search/?api=1&query=Kurniki+9,+31-156+Cracow',
    city: 'Cracow',
    street: 'Kurniki 9',
    postal: '31-156',
    country: 'Poland',
  },
];

export const paths = [
  {
    name: 'Services',
    link: '/services/',
    children: [
      {
        link: '/services/software-development-outsourcing/',
        name: 'Software Development Outsourcing',
      },
      {
        link: '/services/full-cycle-product-development/',
        name: 'Full Cycle Product Development',
      },
      {
        link: '/services/code-audit/',
        name: 'Code audit & UX audit',
      },
      {
        link: '/services/full-cycle-product-development/prototyping/',
        name: 'Product Prototyping',
      },
      {
        link: '/services/mvp-development/',
        name: 'MVP Development services',
      },
      {
        link: '/services/product-market-fit-expanding-demand/',
        name: 'Product-Market Fit',
      },
      {
        link: '/services/scaling-your-product-thriving-in-the-market/',
        name: 'Product Scaling',
      },
      {
        link: '/services/product-design-and-development/',
        name: 'Product Design and Development',
      },
      {
        link: '/services/development-teams/',
        name: 'Development Teams',
      },
      {
        link: '/services/ai-software-development-consulting/',
        name: 'AI Services',
      },
      {
        link: '/services/digital-transformation/',
        name: 'Digital Transformation',
      },
      {
        link: '/services/devops-consulting-services/',
        name: 'DevOps Consulting',
      },
      {
        link:
          '/blog/setting-the-benchmark-boldares-service-standards-in-a-nutshell/',
        name: 'Our service standards',
      },
      {
        link: '/ux-ui-design-consulting-services/',
        name: 'UX/UI Design Services',
      },
    ],
  },
  {
    name: 'About us',
    link: '/about/',
  },
  {
    name: 'Resources',
    link: '/resources/',
  },
  {
    name: 'New Normal',
    link: '/new-normal/',
  },
  {
    name: 'Contact',
    link: '/contact/',
  },
  {
    name: 'Career',
    link: '/career/',
  },
  {
    name: 'Blog',
    link: '/blog/',
  },
  {
    name: 'Boldare Boards',
    link: 'https://boards.boldare.com/',
  },
  {
    name: 'Privacy Policy',
    link: '/privacy-policy/',
  },
  {
    name: 'Newsroom',
    link: '/newsroom/',
  },
  {
    name: 'Agile Product Builders',
    link: 'https://www.boldare.com/apb-community-newsletter/',
  },
  {
    name: 'Investor relations',
    link: '/investor-relations/',
  },
];
