import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-flexbox-grid';
import { kebabCase } from 'lodash';
import './CountryTimeline.scss';

import ContactPerson from 'components/ContactPerson';

const CountryTimeline = ({ data, contactPersonData }) => {
  const countries = [
    {
      name: 'Poland',
      cities: data.filter(city => city.country === 'Poland'),
      contactPerson: contactPersonData.filter(
        person => person.country === 'Poland',
      ),
    },
    // {
    //   name: 'Netherlands',
    //   cities: data.filter(city => city.country === 'The Netherlands'),
    //   contactPerson: contactPersonData.filter(
    //     person => person.country === 'The Netherlands',
    //   ),
    // },
  ];

  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const [selectedCityImage, setSelectedCityImage] = useState(data[0]);
  const [addressesVisible, setAddressesVisible] = useState('visible');
  const [imageClass, setImageClass] = useState('visible');

  const handleCityImageChange = city => {
    if (!city) return;
    setImageClass('hidden');
    setTimeout(() => {
      setSelectedCityImage(city);
      setImageClass('visible');
    }, 300);
  };

  const handleCountryClick = country => {
    if (!country.cities.length) return;
    setSelectedCountry(country);
    handleCityImageChange(country.cities[0]);

    setAddressesVisible('hidden');
    setTimeout(() => {
      setAddressesVisible('visible');
    }, 300);
  };

  const renderPlaces = () => {
    if (!selectedCountry) return null;

    return selectedCountry.cities.map(item => (
      <div
        key={item.title}
        className={`contact-offices__info-place ${addressesVisible}`}
      >
        <p className={`contact-offices__info-place--hq text--bold`}>
          {item.title}
        </p>
        <p className={'contact-offices__info-address'}>
          <a href={item.map} target={'_blank'} rel={'noopener noreferrer'}>
            <span
              id={`contact-offices-map-${kebabCase(item.city.toLowerCase())}`}
              className={'text--white'}
            >
              {item.street}
              <br />
            </span>
          </a>
          {item.postal}&nbsp;{item.city}
          <br />
          {item.country}
        </p>
      </div>
    ));
  };

  const renderContactPersons = () => {
    return selectedCountry.contactPerson.map(person => {
      return (
        <div className={`contact-offices__person ${addressesVisible}`}>
          <ContactPerson person={person} />
        </div>
      );
    });
  };

  return (
    <div className={'country-timeline'}>
      <div className={'timeline'}>
        {countries.map((country, index) => (
          <>
            {index > 0 && <div className={'separator'} />}
            <button
              className={`country-button ${
                selectedCountry?.name === country.name ? 'active' : ''
              }`}
              // onClick={() => handleCountryClick(country)}
              // disabled={!country.cities.length}
              style={{ cursor: 'default' }}
            >
              {country.name}
            </button>
          </>
        ))}
      </div>
      <div className={'details'}>
        <Col sm={12} md={6} lg={6} className={'contact-offices-description'}>
          <div className={'contact-offices__info-places'}>
            {renderPlaces()}
          </div>
          <div className={`contact-offices__divider ${imageClass}`} />
          {renderContactPersons()}
        </Col>
        <Col sm={12} md={6} lg={6}>
          <div className={`image-container ${imageClass}`}>
            <img
              src={selectedCityImage.image}
              alt={`${selectedCityImage.city}`}
              loading={'lazy'}
            />
          </div>
        </Col>
      </div>
    </div>
  );
};

export default CountryTimeline;

CountryTimeline.propTypes = {
  data: PropTypes.array.isRequired,
  contactPersonData: PropTypes.array.isRequired,
};
