import BOX_TYPE from 'config/constants/boxTypes';
import {
  CASE_STUDIES_COUNTRY_REGION_MAP,
  CONTINENT_CASE_STUDIES_REGION_MAP,
  Region
} from 'views/work/subcomponents/CaseStudies/data/enums';

export const sortEntries = entries =>
  entries.sort((a, b) => {
    if (a.pinned && !b.pinned) return -1;
    if (!a.pinned && b.pinned) return 1;
    if (a.pinned === b.pinned) {
      const prioA = a.priority ?? Infinity;
      const prioB = b.priority ?? Infinity;
      if (prioA < prioB) return -1;
      if (prioA > prioB) return 1;
    }
    return 0;
  });

export const filterEntries = (data, filters) =>
  data.filter(item => {
    for (const [filterKey, filterValue] of Object.entries(filters)) {
      if (filterValue === null) continue;
      const itemValues = item.content[filterKey];
      if (itemValues === undefined || itemValues === null || itemValues.length === 0) return false;
      return itemValues.some(
        value => value.toLowerCase() === filterValue.toLowerCase(),
      );
    }
    return true;
  });

export const arrangeEntries = (sortedEntries, typePattern) => {
  const queue = [...sortedEntries];
  const result = [];
  while (queue.length > 0) {
    const patternIndex = result.length % typePattern.length;
    // try to find matching entry
    let pick = queue.find(({ type }) => type === typePattern[patternIndex]);
    // if not found, pick first from queue
    if (!pick) pick = queue.shift();
    // otherwise remove pick from queue
    else queue.splice(queue.indexOf(pick), 1);
    // add pick to results
    result.push(pick);
  }
  return result;
};

export const createBlogArticleMap = (entries, articles) => {
  const map = new Map();
  for (const entry of entries) {
    if (entry.type !== BOX_TYPE.BLOG) continue;
    map.set(
      entry.id,
      articles.find(
        edge =>
          edge.node.frontmatter.title.toLowerCase() ===
          entry.title.toLowerCase(),
      ),
    );
  }
  return map;
};

export const getCaseStudiesUserRegion = (userCountryName, userContinent) => {
  return CASE_STUDIES_COUNTRY_REGION_MAP[userCountryName]
    ?? CONTINENT_CASE_STUDIES_REGION_MAP[userContinent]
    ?? Region.Europe;
}
